import React, { Component } from 'react'
import TableOrders from '../../components/Orders/TableOrders';
import { getI18n } from 'react-i18next';

class OnHoldOrders extends Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{getI18n().t('ORDER_LIST')}</h4>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <TableOrders onHold={true}/>
                </div>

            </div>
        )
    }
}

export default OnHoldOrders;
