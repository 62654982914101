import React, { Component } from "react";
import { API, ROUTER } from "../../env";
import swal from "sweetalert";
import { getI18n } from "react-i18next";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ToggleButton from "react-toggle-button";

class AddForm extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      name: "",
      category: undefined,
      categories: [],
      description: "",
      price: 1,
      show: true,
      permanent: false,
      selectedOptions: [],
      options: [],
      selectedSuppelemnts: [],
      supplements: [],


    };
    this.selectCategory = this.selectCategory.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }
  componentDidMount() {
    window.$(".filestyle").filestyle({
      buttonName: "btn-primary",
    });
    /**
    * Get Categories list
    **/
    fetch(API.category.all, {
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((categories) => this.setState({ categories }))
      .catch((e) => swal("Erreur", e.message, "error"));
    /**
    * Get Options List
    **/
    fetch(API.option.all, {
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((options) => this.setState({ options }))
      .catch((e) => swal("Erreur", e.message, "error"));
    /**
    * Get Supplements List
    **/
    fetch(API.supplement.all, {
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((supplements) => this.setState({ supplements }))
      .catch((e) => swal("Erreur", e.message, "error"));
  }
  create() {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("price", this.state.price);
    formData.append("show", this.state.show);
    formData.append("permanent", !this.state.permanent);
    formData.append(
      "category",
      this.state.category ? this.state.category.value : null
    );
    if (
      window.$(".filestyle")[0].files &&
      window.$(".filestyle")[0].files.length > 0
    ) {
      formData.append("file", window.$(".filestyle")[0].files[0], "file");
    }

    let options = [];
    this.state.selectedOptions.forEach(elem => {
        options.push(elem.value);
    });
    for (let i = 0; i < options.length; i++) {
      formData.append('options[]', options[i]);
    }
    let supplements = [];
    this.state.selectedSuppelemnts.forEach(elem => {
      supplements.push(elem.value);
    });
    for (let i = 0; i < supplements.length; i++) {
      formData.append('supplements[]', supplements[i]);
    }



    fetch(API.product.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal(this._.t("ERROR"), response.message, "error");
        }
        return swal(
          this._.t("SUCCESS"),
          this._.t("DATA_SAVED"),
          "success"
        ).then(() => {
          window.location = ROUTER.PRODUCTS.LIST;
        });
      })
      .catch((e) => swal(this._.t("ERROR"), this._.t("DATA_ERR"), "error"));
  }
  handleChange(event) {
    const state = {};
    state[event.target.id] = event.target.value;
    this.setState(state);
  }
  selectCategory(category) {
    this.setState({ category });
  }
  onEditorStateChange(editorState) {
    this.setState({ editorState });
  }

  render() {
    const i18n = getI18n();
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_NAME")}
                </label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    placeholder={i18n.t("PRODUCT_NAME_PH")}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_PRICE")}
                </label>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="number"
                    id="price"
                    placeholder={i18n.t("PRODUCT_PRICE_PH")}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_IMAGE")}
                </label>
                <div className="col-sm-10">
                  <input type="file" className="filestyle" />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_CATEGORY")}
                </label>
                <div className="col-sm-10">
                  <Select
                    name="category"
                    placeholder={i18n.t("PRODUCT_CATEGORY")}
                    value={this.state.category}
                    options={
                      this.state.categories
                        ? this.state.categories.map((category) => ({
                            value: category._id,
                            label: category.name,
                          }))
                        : []
                    }
                    onChange={this.selectCategory}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_OPTIONS")}
                </label>
                <div className="col-sm-10">
                  <Select
                    isMulti
                    name="options"
                    placeholder={i18n.t("PRODUCT_OPTIONS")}
                    value={this.state.selectedOptions}
                    options={
                      this.state.options
                        ? this.state.options.map((option) => ({
                            value: option._id,
                            label: option.name,
                          }))
                        : []
                    }
                    onChange={(options)=> this.setState({selectedOptions: options})}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_SUPPLEMENTS")}
                </label>
                <div className="col-sm-10">
                  <Select                                      
                    isMulti
                    name="supplements"
                    placeholder={i18n.t("PRODUCT_SUPPLEMENTS")}
                    value={this.state.selectedSuppelemnts}
                    options={
                      this.state.supplements
                        ? this.state.supplements.map((supplement) => ({
                            value: supplement._id,
                            label: supplement.name,
                          }))
                        : []
                    }
                    onChange={(supplements)=> this.setState({selectedSuppelemnts: supplements})}
                  />
                </div>
              </div>
              
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_VISIBLE")}
                </label>
                <div className="col-sm-10">
                  <ToggleButton
                    inactiveLabel={
                      <div style={{ textAlign: "center" }}>
                        <i
                          style={{ fontSize: 20, color: "red" }}
                          class="mdi mdi-eye-off-outline"
                        ></i>
                      </div>
                    }
                    activeLabel={
                      <div style={{ textAlign: "center" }}>
                        <i
                          style={{ fontSize: 20, color: "white" }}
                          class="mdi mdi-eye-outline"
                        ></i>
                      </div>
                    }
                    value={this.state.show}
                    onToggle={(show) => {
                      this.setState({
                        show: !show,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_PERMANENT")}
                </label>
                <div className="col-sm-10">
                  <ToggleButton
                    inactiveLabel={
                      <div style={{ textAlign: "center" }}>
                        <i
                          style={{ fontSize: 20, color: "red" }}
                          class="mdi mdi-bookmark-off-outline"
                        ></i>
                      </div>
                    }
                    activeLabel={
                      <div style={{ textAlign: "center" }}>
                        <i
                          style={{ fontSize: 20, color: "white" }}
                          class="mdi mdi-bookmark-check"
                        ></i>
                      </div>
                    }
                    value={this.state.permanent}
                    onToggle={(permanent) => {
                      console.log(permanent);
                      this.setState({
                        permanent: !permanent,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="name" className="col-sm-2 col-form-label">
                  {i18n.t("PRODUCT_DESCRIPTION")}
                </label>
                <div className="col-sm-10">
                  <textarea className="form-control" value={this.state.description} placeholder={this._.t("PRODUCT_DESCRIPTION_PH")} onChange={(description) => this.setState({ description: description.target.value })}></textarea>
                </div>
              </div>
              <div className="form-group mb-0" style={{ float: "right" }}>
                <div>
                  <button
                    type="reset"
                    className="btn btn-secondary waves-effect  mr-1"
                    onClick={() => (window.location = ROUTER.CATEGORIES.LIST)}
                  >
                    {i18n.t("CANCEL")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light mr-1"
                    onClick={this.create.bind(this)}
                  >
                    {i18n.t("SUBMIT")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddForm;
