import React, { Component } from 'react'
import EditForm from '../../components/Categories/EditForm';
import { API, ROUTER } from '../../env';
import swal from 'sweetalert';

export default class EditCategory extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         id: this.props.match.params.id,
      }
    }
    componentWillMount(){
        fetch(API.category.info(this.state.id), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            }
        })
            .then(res => res.json())
            .then(category => category.error ? window.location = ROUTER.ERROR : this.setState({category}))
            .catch(error => swal('Error', error.message, 'error'));
    }
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <EditForm category={this.state.category}/>
                </div>

            </div>
        )
    }
}
