import React, { useState, useEffect } from "react";
import { getI18n } from "react-i18next";
import { useForm } from "react-hook-form";
import { API, STATIC } from "../../../env";
import swal from "sweetalert";
import SlidesModal from "./SlidesModal";
import "../style.css";
function SlidesPage() {
  const _ = getI18n();
  const { register, handleSubmit, errors, reset } = useForm();
  const [data, setData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    window.$(".filestyle").filestyle({
      buttonName: "btn-primary",
    });
  }, []);
  const editSlide = (index) => {
    setCurrentSlide(data[index]);
    setCurrentIndex(index);
    window.$("#myModal").modal("show");
  };
  const deleteSlide = (index) => {
    swal({
      title: _.t("ARE_YOU_SURE"),
      text: _.t("DELETE_WARNING"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const temp = [...data];

        // removing the element using splice
        temp.splice(index, 1);
        console.log(temp);
        // updating the list
        setData(temp);
        const formData = new FormData();
        formData.append("name", "SLIDES");
        formData.append("content", JSON.stringify(temp));
        formData.append("delete", true);

        fetch(API.content.update, {
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.error) {
              return swal(_.t("ERROR"), response.message, "error");
            }
            // setData(response);
          })
          .catch((e) => swal(_.t("ERROR"), _.t("SERVER_ERR"), "error"));
      }
    });
  };
  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "SLIDES",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("this is slides data ");
          console.log(response);
          if (
            Object.keys(response).length !== 0 &&
            response.constructor !== Object
          ) {
            setData(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <h4 className="page-title">{_.t("SLIDES_LIST")}</h4>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group mb-0" style={{ float: "right" }}>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light mr-1"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                        >
                          {_.t("ADD_SLIDE")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SlidesModal
                dataSlides={data}
                setData={setData}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                setCurrentIndex={setCurrentIndex}
                currentIndex={currentIndex}
              />
              {data.map((elem, index) => (
                <div className="card card-custom border-container">
                  <div>
                    <div className="d-flex">
                      <div className="d-flex mr-5">
                        <img
                          style={{ width: "300px", height: "auto" }}
                          src={STATIC.ROUTE(elem.image)}
                        />
                      </div>

                      <div className="container-fluid">
                        <ul className="list-inline m-0 float-right">
                          <li className="list-inline-item">
                            <button
                              className="btn btn-success btn-sm rounded-0"
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title
                              data-original-title="Edit"
                              onClick={() => editSlide(index)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                          </li>
                          <li className="list-inline-item">
                            <button
                              className="btn btn-danger btn-sm rounded-0"
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title
                              data-original-title="Delete"
                              onClick={() => deleteSlide(index)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </li>
                        </ul>
                        <label>{_.t("TITLE")}</label>
                        <p>{elem.title}</p>
                        <label>{_.t("SUB_TITLE")}</label>
                        <p>{elem.subTitle}</p>
                        <label>{_.t("Paragraphe")}</label>
                        <p>{elem.paragraph}</p>
                        <label>{_.t("CONTENT_BUTTON")}</label>
                        <p>{elem.contentButton}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SlidesPage;

/*
useEffect(() => {
  window.$(".filestyle").filestyle({
    buttonName: "btn-primary",
  });
}, []);
<div className="page-title-box">
  <div className="row align-items-center">
    <div className="col-sm-6">
      <h4 className="page-title">Option</h4>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">Liste des options</li>
      </ol>
    </div>
    <div className="col-sm-6">
      <div className="form-group mb-0" style={{ float: "right" }}>
        <div>
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light mr-1"
          >
            Ajouter une option
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
*/
