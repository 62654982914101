import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import Moment from 'react-moment';
import { getI18n } from 'react-i18next';

class TableClients extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            clients: [],
            total: 0,
            count: 5,
            totalPage: 1,
            typeClient: "all",
            name: ''
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }
    /*
    componentDidUpdate() {
        this.loadPage();
    }
    */
    componentDidUpdate(prevProps, prevState) {
        if (prevState.typeClient !== this.state.typeClient) {
            this.loadPage();
        }
    }
    handleChange(event) {
        const state = {};
        state[event.target.id] = event.target.value;
        this.setState(state);
    }

    nextPage() {
        let { page } = this.state;
        page++;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    prevPage() {
        let { page } = this.state;
        page--;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    loadPage(page = 1, name = '') {
        fetch(API.clients.list(page, this.state.count, name, this.state.typeClient), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                console.log(response)
                this.setState({ page, ...response });
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    delete(id) {
        swal({
            title: this._.t('ARE_YOU_SURE'),
            text: this._.t('DELETE_WARNING'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(API.clients.remove, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': localStorage.getItem('token'),
                            'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                        },
                        body: JSON.stringify({ id })
                    })
                        .then(res => res.json())
                        .then(response => {
                            if (response.error) {
                                return swal(this._.t('ERROR'), response.message, 'error');
                            }
                            return swal(this._.t('SUCCESS'), this._.t('CLIENT_REMOVED'), 'success').then(() => window.location = ROUTER.CLIENTS.LIST);
                        })
                        .catch(e => swal(this._.t('ERROR'), e.message, 'error'))
                }
            });
    }
    filter(event) {
        let name = event.target.value;
        this.loadPage(1, name);
        this.setState({name})
    }
    render() {
        const renderContent = (elements) => elements.map(element => {

            return (
                <tr key={element._id}>
                    <td style={{ padding: 15 }}>
                        <div style={{ height: 75 }}>
                            <img alt={`${element.firstName} ${element.lastName}`} src={STATIC.ROUTE(element.picture ? element.picture : '/')} style={{ height: '100%', width: 'auto' }} />
                        </div>
                    </td>
                    <td><a href={ROUTER.CLIENTS.INFO(element._id)}>{element.firstName} {element.lastName}</a></td>
                    <td>{element.phone}</td>
                    <td>{element.email}</td>
                    <td>{element.address ? `${element.address}, ${element.city}, ${element.zipCode}` : ''}</td>
                    <td>{element.reseller ? this._.t('CLIENT_RESELLER') : this._.t('CLIENT_NORMAL')}</td>
                    <td>{element.enseigne ? element.enseigne : '-'}</td>


                    <td>
                        <div className="button-items">
                            <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => window.location = ROUTER.CLIENTS.EDIT(element._id)}>{this._.t('EDIT')}</button>
                            <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={() => this.delete(element._id)}>{this._.t('DELETE')}</button>
                        </div>
                    </td>



                </tr>
            )
        }
        )
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                            <div className="form-group row">
                                                <label for="name" className="col-sm-2 col-form-label">{this._.t('SEARCH')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" type="text" id="name" placeholder={this._.t('SEARCH_CLIENT')} onChange={this.filter.bind(this)} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                            <div className="form-group row">
                                                <label for="typeClient" className="col-sm-2 col-form-label">{this._.t('CLIENT_TYPE')}</label>
                                                <div className="col-sm-10">
                                                    <select class="form-control " id="typeClient" value={this.state.typeClient} onChange={this.handleChange.bind(this)}>
                                                        <option value="all">{this._.t('CLIENT_ALL')}</option>
                                                        <option value="normal">{this._.t('CLIENT_NORMAL')}</option>
                                                        <option value="reseller">{this._.t('CLIENT_RESELLER')}</option>

                                                    </select>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                    <div className="col-sm-12">
                                        <table className="table mb-0">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{this._.t('CLIENT_IMAGE')}</th>
                                                    <th>{this._.t('CLIENT_NAME')}</th>
                                                    <th>{this._.t('CLIENT_PHONE')}</th>
                                                    <th>{this._.t('CLIENT_EMAIL')}</th>
                                                    <th>{this._.t('CLIENT_ADDRESS')}</th>
                                                    <th>{this._.t('CLIENT_TYPE')}</th>
                                                    <th>{this._.t('CLIENT_ENSEIGN')}</th>
                                                    <th>Actions</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderContent(this.state.clients)}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.clients.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                                <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{'< Previous'}</button>
                                                <span style={{ marginLeft: 15, marginRight: 15 }}>{this.state.page} / {this.state.totalPage}</span>
                                                <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{'Next >'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TableClients;
