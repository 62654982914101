import React, { Component } from 'react'
import TableMessage from '../../components/Messages/TableMessage';
import { getI18n } from 'react-i18next';

class ListMessage extends Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{getI18n().t('MESSAGES')}</h4>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <TableMessage />
                </div>

            </div>
        )
    }
}

export default ListMessage;
