import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import Moment from 'react-moment';
import { getI18n } from 'react-i18next';

class TableSupplements extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            supplements: [],
            total: 0,
            count: 5,
            totalPage: 1,
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }
    nextPage() {
        let { page } = this.state;
        page++;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    prevPage() {
        let { page } = this.state;
        page--;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    loadPage(page = 1, name = '') {
        fetch(API.supplement.list(page, this.state.count, name), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                this.setState({page, ...response});

            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    delete(id) {
        console.log(id)
        swal({
            title: this._.t('ARE_YOU_SURE'),
            text: this._.t('DELETE_WARNING'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(API.supplement.remove, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': localStorage.getItem('token'),
                        },
                        body: JSON.stringify({ id })
                    })
                        .then(res => res.json())
                        .then(response => {
                            if (response.error) {
                                return swal(this._.t('ERROR'), response.message, 'error');
                            }
                            return swal(this._.t('SUCCESS'), this._.t('DATA_SUPPLEMENT_REMOVED'), 'success').then(() => window.location = ROUTER.SUPPLEMENTS.LIST);
                        })
                        .catch(e => swal(this._.t('ERROR'), e.message, 'error'))
                }
            });
    }
    filter(event) {
        let searchValue = event.target.value;
        this.loadPage(1, searchValue);
    }
    
  render() {
        const renderContent = (elements) => elements.map(element => {

            const createMarkup = ()=> {
                return { __html: element.description };
              }
            const items= [];
            element.items.forEach((elem, index, array )=> {
                if (index === array.length - 1) {
                    items.push(elem.name)

                }else {
                    items.push(elem.name)

                    items.push(' , '); 

                }
                
            })

        return (
            <tr key={element._id}>
            
                <td>{element.name}</td>
                <td><div dangerouslySetInnerHTML={createMarkup()} /> </td>
                <td>{element.items ? items : '-'}</td>
                <td>
                <div className="button-items">
                        <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => window.location = ROUTER.SUPPLEMENTS.EDIT(element._id)}>{this._.t('EDIT')}</button>
                        <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={() => this.delete(element._id)}>{this._.t('DELETE')}</button>
                    </div>
                </td>
            </tr>
        )
        })
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                            <div className="form-group row">
                                                <label for="name" className="col-sm-2 col-form-label">{this._.t('SEARCH')}</label>
                                                <div className="col-sm-10">
                                                    <input className="form-control" type="text" id="name" placeholder={this._.t('SEARCH_PRODUCT')} onChange={this.filter.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <table className="table mb-0">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{this._.t('SUPPLEMENT_NAME')}</th>
                                                    <th>{this._.t('SUPPLEMENT_DESCRIPTION')}</th>
                                                    <th>{this._.t('SUPPLEMENT_ITEMS')}</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderContent(this.state.supplements)}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.supplements.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                                <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{'< Previous'}</button>
                                                <span style={{ marginLeft: 15, marginRight: 15 }}>{this.state.page} / {this.state.totalPage}</span>
                                                <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{'Next >'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TableSupplements;