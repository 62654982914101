import React from "react";
import "./style.css";
import TimePicker from "react-times";
import { getI18n } from "react-i18next";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    this.state = {
      deliveryStartTime: "08:00",
      commandCloseTime: "",
      deliveryEndTime: "17:00",
    };
  }
  onCloseTimeChange(options) {
    this.setState({ commandCloseTime: `${options.hour}:${options.minute}` });
  }
  onStartTimeChange(options) {
    this.setState({ deliveryStartTime: `${options.hour}:${options.minute}` });
  }
  onSumbit() {
    console.log(this.props.index);
  }

  render() {
    const days = [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ];
    return (
      <div className="container-modal">
        <div className="header-modal">
          <h4>
            {" "}
            {this._.t("DELIVERY_HOUR")} {days[this.props.index]}
          </h4>
        </div>
        <div className="body-modal">
          <div style={{ textAlign: "center", margin: 15 }}>
            <div className="form-group row" style={{ margin: 15 }}>
              <div className="">
                <label for="firstName" className="form-label">
                  {this._.t("DELIVERY_START")}
                </label>
                <TimePicker
                  focused={true}
                  minuteStep={1}
                  time={this.state.deliveryStartTime}
                  onTimeChange={this.onStartTimeChange.bind(this)}
                  closeOnOutsideClick={false}
                  autoClose={false}
                  trigger={<div />}
                  defaultTime={this.state.deliveryStartTime}
                />
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", margin: 15 }}>
            <div className="form-group row" style={{ margin: 15 }}>
              <div className="">
                <label for="firstName" className="form-label">
                  {this._.t("DELIVERY_END")}
                </label>
                <TimePicker
                  focused={true}
                  minuteStep={1}
                  time={this.state.deliveryEndTime}
                  onTimeChange={(options) =>
                    this.setState({
                      deliveryEndTime: `${options.hour}:${options.minute}`,
                    })
                  }
                  closeOnOutsideClick={false}
                  autoClose={false}
                  trigger={<div />}
                  defaultTime={this.state.deliveryEndTime}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-modal">
          <button
            type="submit"
            className="btn btn-primary waves-effect waves-light button-modal"
            onClick={() =>
              this.props.onSumbit(
                this.state.deliveryStartTime,
                this.state.deliveryEndTime,
                this.props.index
              )
            }
          >
            {this._.t("SUBMIT")}
          </button>
          <button
            type="submit"
            className="btn btn-secondary waves-effect waves-light button-modal"
            onClick={this.props.closeModal}
          >
            {this._.t("CANCEL")}
          </button>
        </div>
      </div>
    );
  }
}

export default Modal;
