import React from 'react';
import {getI18n} from "react-i18next";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import {ROUTER, API, STATIC} from "../../env";
import Moment from "react-moment";
import moment from 'moment';
import { fr } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range';
import swal from 'sweetalert';

import 'react-data-table-component-extensions/dist/index.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.css'
moment.locale('fr');

function ClientInformation({client, updateRange}) {
    const i18n = getI18n();
    const [columns, setColumns] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [range, setRange] = React.useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [filterRange, setFilterRange] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(false);


    const handleSelectDate = (selectedRange) => {
        setRange(selectedRange.selection);
        setFilterRange(true);
    }
    React.useEffect(() => {
        const orders = client.orders;
        if (orders) {
            setData(orders);
            const col = [
                {
                    name: 'Ref',
                    selector: 'reference',
                    cellExport: row => row.reference,
                    cell: element => <a href={ROUTER.ORDERS.INVOICE(element._id)}>{element.reference}</a>
                },
                {
                    name: 'Livraison/Emporter',
                    cellExport: row => row.pickup ?  'Pick-up' : `Livraison à: ${row.address}, ${row.city} ,${row.country} ${row.zipCode}`,
                    cell: element => <span>
                        {element.pickup ?
                            'Pick-up' :
                            <p>{i18n.t('DELIVER_TO')}: <br/>{element.address}, {element.city}<br/>{element.country} {element.zipCode}
                            </p>
                        }
                        </span>
                },
                {
                    name: 'Total',
                    selector: 'total',
                    sortable: true,
                    cellExport: row => `${Number(row.total).toFixed(2)} €`,
                    cell: element => <span>{Number(element.total).toFixed(2)} €</span>
                },
                {
                    name: 'Mode paiement',
                    cellExport: row => row.paymentGateway === 'Paiement en ligne' ? `Paiement en ligne (status: ${row.transaction ? row.transaction.status : 'En cours'})` : row.paymentGateway,
                    cell: element => <span>{
                        element.paymentGateway === 'Paiement en ligne' ?
                            (
                                <div>
                                    <p>Paiement en ligne</p>
                                    <p>Status: {element.transaction ? element.transaction.status : 'En cours'}</p>
                                </div>
                            )
                            :
                            element.paymentGateway
                    }</span>
                },
                {
                    name: 'Date Livraison/Passage',
                    cellExport: row => moment(row.date).locale('fr').format('llll'),
                    cell: element => <Moment locale={'fr'} format='llll'>{element.date}</Moment>
                },
                {
                    name: 'Date de commande',
                    cellExport: row => moment(row.created_at).locale('fr').format('llll'),
                    cell: element => <Moment locale={'fr'} format='llll'>{element.created_at}</Moment>
                },
            ];
            setColumns(col);
        }
    }, [client]);
    const exportSales = () => {
        const body = {};
        body.orders = data.map(order => order._id);
        body.client = client._id;
        fetch(API.clients.exportInfo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Erreur', response.message, 'error');
                }
                window.open(STATIC.ROUTE(response.url), '_blank');
            })
    }
    return(
        <div>
            <div className="row">
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <address>
                                <strong>{i18n.t("CLIENT_INFO")}:</strong>
                                <br/>
                                {client.firstName} {client.lastName}
                                <br/>
                                {client.email
                                    ? `Email: ${client.email}`
                                    : "Email: -"}
                                <br/>
                                {client.phone
                                    ? `Tel: ${client.phone}`
                                    : "Tel: -"}
                                <br/>
                            </address>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <h4>Totale Des commandes</h4>
                            <h6>{
                                client.orders ?
                                    client.orders.map(order => order.total).reduce((a, b) => a+b, 0)
                                    :
                                    0
                            } €</h6>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <h4>Nombre Des commandes</h4>
                            <h6>{
                                client.orders ?
                                    client.orders.length : 0
                            } Commandes</h6>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className={"row"}>
                                {
                                    showFilter ?
                                        <div style={{width: '100%'}}>
                                            <DateRangePicker
                                            locale={fr}
                                            ranges={[range]}
                                            onChange={handleSelectDate}
                                        />
                                            <div style={{justifyContent: 'flex-end', width: '100%', display: 'flex'}}>
                                                <button className={"btn btn-primary"} onClick={() => {updateRange(range); setShowFilter(false)}}>{filterRange ? `Filtrer De ${moment(range.startDate).format('lll')} à ${moment(range.endDate).format('lll')}` : 'Filtrer par Date'}</button>
                                            </div>
                                        </div>
                                        :
                                        <div style={{justifyContent: 'flex-end', width: '100%', display: 'flex'}}>
                                            <button className={"btn btn-primary"}  onClick={() => setShowFilter(true)}>{filterRange ? `${moment(range.startDate).format('lll')} - ${moment(range.endDate).format('lll')}` : 'Filtrer par Date'}</button>
                                            <button className={"btn btn-danger"}  onClick={exportSales}>{'exporter les données de ventes'}</button>
                                        </div>
                                }
                            </div>
                            <DataTableExtensions
                                columns={columns}
                                data={data}
                                exportHeaders={true}
                            >
                                <DataTable
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientInformation;
