import React, { Component } from 'react'
import TableSites from '../../components/Sites/TableSites';
import { getI18n } from 'react-i18next';
class ListClients extends Component {
    render() {
        const i18n = getI18n();
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{i18n.t('SITES')}</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">{i18n.t('SITES_LIST')}</li>
                                </ol>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-0" style={{ float: "right" }}>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableSites />
                </div>

            </div>
        )
    }
}

export default ListClients;