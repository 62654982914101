import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next'

class EditForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.category ? this.props.category._id : '',
            parent: undefined,
            picture: this.props.category ? this.props.category.picture : undefined,
            parents: [{ value: null, label: 'Aucune parent' }],
            displayOrder:this.props.category ? this.props.category.displayOrder : 0,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.category) {
            this.setState({
                picture: nextProps.category.picture,
                name: nextProps.category.name,
                parent: nextProps.category.parent ? { value: nextProps.category.parent._id, label: nextProps.category.parent.name } : undefined,
                id: nextProps.category._id,
                displayOrder: nextProps.category.displayOrder,
            });
        }
    }

    componentDidMount() {
        window.$('.filestyle').filestyle({
            buttonName: 'btn-primary'
        });
        fetch(API.category.all, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            }
        })
            .then(res => res.json())
            .then(parents => this.setState({ parents: [...this.state.parents, ...parents] }))
            .catch(e => swal('Erreur', e.message, 'error'));
    }

    update() {
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('displayOrder', this.state.displayOrder);
        if (this.state.parent) {
            formData.append('parent', this.state.parent.value);
        }
        if (window.$('.filestyle')[0].files && window.$('.filestyle')[0].files.length > 0) {
            formData.append('file', window.$('.filestyle')[0].files[0], 'file');
        }
        fetch(API.category.update(this.state.id), {
            method: 'POST',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
            body: formData,
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                return swal('Success', 'Category has been updated', 'success').then(() => { window.location = ROUTER.CATEGORIES.LIST });
            })
            .catch(e => swal('Error', 'Error while updating category', 'error'));
    }

    handleChange(event) {
        const state = {}
        state[event.target.id] = event.target.value;
        this.setState(state);
    }

    render() {
        const i18n = getI18n();
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title">{i18n.t('EDIT_CATEGORY')}</h4>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_NAME')}</label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="text" id="name" placeholder="Type your category name ..." value={this.state.name} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label"></label>
                                {this.state.picture ?
                                    <img alt={this.state.name} src={STATIC.ROUTE(this.state.picture)} style={{ height: 100, margin: 15 }} />
                                    :
                                    <div />
                                }
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_IMAGE')}</label>
                                <div className="col-sm-10">
                                    <input type="file" className="filestyle" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_DISPLAY_ORDER')}</label>
                                <div className="col-sm-10">
                                    <input className="form-control" min={0} type="number" id="displayOrder" placeholder={i18n.t('CATEGORY_DISPLAY_ORDER_PH')} onChange={this.handleChange.bind(this)}/>
                                </div>
                            </div>
                            <div className="form-group mb-0" style={{ float: 'right' }}>
                                <div>
                                    <button type="reset" className="btn btn-secondary waves-effect  mr-1" onClick={() => window.location = ROUTER.CATEGORIES.LIST}>{i18n.t('CANCEL')}</button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" onClick={this.update.bind(this)}>{i18n.t('SUBMIT')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditForm;