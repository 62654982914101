import React from "react";
import Pages from "../../components/Content/Pages";
function Content() {
  return (
    <div className="content">
      <div className="container-fluid" style={{ paddingTop: "2%" }}>
        <Pages />
      </div>
    </div>
  );
}
export default Content;
