import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next'
import ToggleButton from 'react-toggle-button'
import Backdrop from "../../components/Backdrop";
import CroppedImageModal from "../../components/CroppedImageModal/index";
import CroppedImage from "../../components/CroppedImage/index";
import './style.css';
import ResellerProduct from './ResellerProduct'
class EditForm extends Component {
    constructor(props) {
        super(props)
        this._ = getI18n();
        this.state = {
            id: this.props.site ? this.props.site._id : '',
            name: this.props.site? this.props.site.name: '',
            file: "",
            imagePreviewUrl: null,
            imageToCrop: null,
            imgData: null,
            showBackdrop: false,
            picture: null,
        }
        this._ = getI18n();
    }

    handleChange(event) {
        const state = {}
        state[event.target.id] = event.target.value;
        this.setState(state);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.site) {
            this.setState({
                id: nextProps.site._id,
                picture: nextProps.site.picture,
                name: nextProps.site.name ? nextProps.site.name : '',
            });
        }
    }

    componentDidMount() {
        window.$('.filestyle').filestyle({
            buttonName: 'btn-primary'
        });
    }
    onChangePicture = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    imgData: reader.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    handleChangeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    imageToCrop: reader.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        e.target.value = null;
    };
    cropChangedHandler = (image) => {
        let reader = new FileReader();
        let file = image;

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };
    fileChange = (e) => {
        this.handleChangeFile(e);
        window.$("#croppedImageModal").modal("show");
    };
    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    validate = () => {
        return true;
    };


    update() {
        const isValid = this.validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("name", this.state.name);
            if (this.state.file !== "") {
                formData.append("file", this.state.file, this.state.file.name);
            }
            fetch(API.sites.update(this.state.id), {
                method: 'POST',
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                    'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                },
                body: formData,
            })
                .then(res => res.json())
                .then(response => {
                    if (response.error) {
                        return swal(this._.t('ERROR'), response.message, 'error');
                    }
                    return swal(this._.t('SUCCESS'), this._.t('SITE_SAVED'), 'success')
                        .then(() => { window.location = ROUTER.SITES });
                })
                .catch(e => swal(this._.t('ERROR'), this._.t('DATA_ERR'), 'error'));
        }
    }

    render() {
        const i18n = getI18n();
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title">{i18n.t('EDITE_SITE')}</h4>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("SITE_NAME")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="name"
                                        placeholder={i18n.t("SITE_NAME_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.name}
                                    />
                                </div>
                            </div>
                             <div className="form-group row">
                                <label className="col-sm-2 col-form-label"></label>

                                {this.state.imagePreviewUrl === null ? (
                                    this.state.picture && (
                                        <img
                                            alt={this.state.firstName}
                                            src={STATIC.ROUTE(this.state.picture)}
                                            style={{ height: 100, margin: 15 }}
                                        />
                                    )
                                ) : (
                                        <img src={this.state.imagePreviewUrl} />
                                    )}
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">
                                    {i18n.t("SITE_IMAGE")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="file"
                                        className="filestyle"
                                        onChange={this.fileChange.bind(this)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group mb-5" style={{ float: 'right' }}>
                                <div>
                                    <button type="reset" className="btn btn-secondary waves-effect  mr-1" onClick={() => window.location = ROUTER.SITES}>{i18n.t('CANCEL')}</button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" onClick={this.update.bind(this)}>{i18n.t('SUBMIT')}</button>
                                </div>
                            </div>
                        
                            {this.state.showBackdrop && (
                                <Backdrop backdropClick={this.handleShowModal} />
                            )}
                            <CroppedImageModal
                                fileChangedHandler={this.cropChangedHandler}
                                file={this.state.file}
                            >
                                <CroppedImage
                                    src={this.state.imageToCrop}
                                    setFile={(file) => this.setState({ file: file })}
                                />
                            </CroppedImageModal>
                           
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditForm;