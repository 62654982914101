import React from "react";
import { getI18n } from "react-i18next";
import MapComponent from "../../components/MapComponent";
import SearchLocationInput from "../../components/SearchLocationInput";
import ToggleButton from "react-toggle-button";

import { STATIC } from "../../env";
import "./style.css";
function General(props) {
  const _ = getI18n();

  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  React.useEffect(() => {
    window.$(".filestyle").filestyle({
      buttonName: "btn-primary",
    });
  }, []);
  return (
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <h4 className="page-title">{_.t("GENERAL")}</h4>
            <ol className="breadcrumb"></ol>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row card-body">
              <div className="col-12">
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("RESTAURANT_NAME")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("RESTAURANT_NAME_PH")}
                      onChange={props.handleChange}
                      name="name"
                      value={props.state.name}
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label"></label>

                  {props.state.imagePreviewUrl === null ? (
                    props.state.logo && (
                      <img
                        alt={props.state.name}
                        src={STATIC.ROUTE(props.state.logo)}
                        style={{ height: 100, margin: 15 }}
                      />
                    )
                  ) : (
                      //<img src={images.accountProfile} alt="profile" />
                      <img src={props.state.imagePreviewUrl} />
                    )}
                  {/*
                  props.state.logo ? (
                    <img
                      alt={props.state.name}
                      src={STATIC.ROUTE(props.state.logo)}
                      style={{ height: 100, margin: 15 }}
                    />
                  ) : (
                    <div />
                  )
                  */}
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("LOGO_IMAGE")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="file"
                      className="filestyle"
                      onChange={props.handleFile}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("MESSAGE_EMAIL")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={_.t("EMAIL_PH")}
                      onChange={props.handleChange}
                      name="email"
                      value={props.state.email}
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("PHONE_NUMBER")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="phone"
                      name="phone"
                      placeholder={_.t("PHONE_NUMBER_PH")}
                      onChange={props.handleChange}
                      value={props.state.phone}
                    />

                    <span className="text-danger"></span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("LOCATION")}
                  </label>

                  <div className="col-sm-10">
                    <SearchLocationInput
                      handleCoordonee={props.handleCoordonee}
                      value={props.state.adress}
                    />

                    <span className="text-danger"></span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("ADRESSE")}
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      name="adress"
                      placeholder={_.t("ADRESSE_PH")}
                      onChange={props.handleChange}
                      value={props.state.adress}
                    />

                    <span className="text-danger"></span>
                  </div>
                </div>

                <div style={{ width: "100%", height: "500px" }}>
                  <MapComponent
                    distance={props.state.zoneDistance}
                    lat={props.state.lat}
                    lng={props.state.lng}
                    setLocation={props.setLocation}
                    drag={true}
                    delivery={props.state.delivery}
                  />
                </div>
                
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">
                    {_.t("ENABLE_COMANDE")}
                  </label>
                  <div className="col-sm-10">
                   <ToggleButton
                      inactiveLabel={
                        <div style={{ textAlign: "center" }}>
                          {_.t("NO")}

                        </div>
                      }
                      activeLabel={
                        <div style={{ textAlign: "center" }}>
                          {_.t("YES")}

                        </div>
                      }
                      value={props.state.enableCommande}
                      onToggle={(enableCommande) => {
                        props.updateEnable(!enableCommande);
                      }}
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>
                
                {
                  props.state.enableCommande ?

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        {_.t("COMMANDE_TIME")}
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={_.t("COMMANDE_TIME_PH")}
                          onChange={props.handleChange}
                          name="commandeTime"
                          value={props.state.commandeTime}
                        />
                        <span className="text-danger"></span>
                      </div>
                    </div>
                    :
                    <></>
                }
                <label className="custom-label"> {_.t("TABLES_LABEL")}</label>
                <div className="table-responsive-xl">
                  <table className="table table-bordered">
                    <thead style={{ textAlign: "center" }}>
                      <tr>
                        <th></th>
                        {props.state.planningCommade.map((elem, index) => {
                          return <th scope="col" key={index}>{days[index]}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props.state.enableCommande ?
                          <tr style={{ textAlign: "center" }}>
                            <td>{_.t("ORDER_HOUR")}</td>
                            {props.state.planningCommade.map((elem, index) => {
                              return (
                                <td key={`days_${index}`}>
                                  {elem.from !== "" && elem.to !== "" ? (
                                    <div
                                      className="time-container"
                                      onClick={() => {
                                        props.handleShowModalCommande(
                                          index,
                                          true,
                                          elem.from,
                                          elem.to
                                        );
                                      }}
                                    >{`${elem.from} - ${elem.to}`}</div>
                                  ) : (
                                      <button
                                        className="btn btn-light"
                                        onClick={() =>
                                          props.handleShowModalCommande(index)
                                        }
                                      >
                                        {_.t("ADD")}
                                      </button>
                                    )}
                                </td>
                              );
                            })}
                          </tr>

                          :
                          <></>
                      }
                      <tr style={{ textAlign: "center" }}>
                        <td>{_.t("OPEN_CLOSE_HOUR")} </td>
                        {props.state.planningOpenClose.map((elem, index) => {
                          return (
                            <td key={`close_hour_${index}`}>
                              {elem.from !== "" && elem.to !== "" ? (
                                <div
                                  className="time-container"
                                  onClick={() => {
                                    props.handleShowModalOpenClose(
                                      index,
                                      true,
                                      elem.from,
                                      elem.to
                                    );
                                  }}
                                >
                                  {`${elem.from} - ${elem.to}`}
                                </div>
                              ) : (
                                  <button
                                    className="btn btn-light"
                                    onClick={() =>
                                      props.handleShowModalOpenClose(index)
                                    }
                                  >
                                    {_.t("ADD")}
                                  </button>
                                )}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={{ float: "right" }}>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={props.update}
                  >
                    {_.t("SUBMIT")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default General;
