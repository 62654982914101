import React from 'react';
import EditForm from "../../components/Clients/EditForm";
import {getI18n} from "react-i18next";
import ClientInformation from '../../components/Clients/ClientInformation'
import {API, ROUTER} from "../../env";
import swal from "sweetalert";
import moment from 'moment';
function OrderList(props) {
    const i18n = getI18n();
    const [client, setClient] = React.useState({});
    React.useEffect(() => {
        const id = props.match.params.id;
        fetch(`${API.clients.info(id)}?dumpOrder=true`, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            }
        })
            .then(res => res.json())
            .then(client => client.error ? window.location = ROUTER.ERROR : setClient(client))
            .catch(error => swal('Error', error.message, 'error'));
    }, [props.match.params]);

    const updateRange = (range) => {
        const id = props.match.params.id;
        fetch(`${API.clients.info(id)}?dumpOrder=true&filterDate=true&from=${moment(range.startDate).format('YYYY-MM-DD')}&to=${moment(range.endDate).format('YYYY-MM-DD')}`, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            }
        })
            .then(res => res.json())
            .then(client => client.error ? window.location = ROUTER.ERROR : setClient(client))
            .catch(error => swal('Error', error.message, 'error'));
    }
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h4 className="page-title">{i18n.t('CLIENT')}</h4>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
                <ClientInformation client={client} updateRange={updateRange}/>
            </div>

        </div>
    );
}

export default OrderList;
