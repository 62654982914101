import React from 'react'
import { STATIC, API } from '../../env';
function SelectedProduct({ resellerProduct, removeProduct, products }) {
    const { product } = resellerProduct;
    const [price, setPrice] = React.useState(resellerProduct.price ? resellerProduct.price : product.price);
    React.useEffect(() => {
        setPrice(resellerProduct.price ? resellerProduct.price : product.price)
    }, [resellerProduct])
    const getCategory = p => {
        for (const product of products) {
            if (product._id === p._id) {
                return product.category.name
            }
        }
        return '';
    }
    const update = () => {
        fetch(API.productReseller.edit(resellerProduct._id), {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
            body: JSON.stringify({price})
        })
            .then(res => res.json())
    }
    return (
        <tr>
            <td>
                <div style={{ display: 'flex', flexDirection: 'row', background: 'white', padding: 5 }}>
                    <div style={{}}>
                        <img style={{ width: 50, height: 50, borderRadius: 75 }} src={STATIC.ROUTE(product.picture ? product.picture : '')} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2 style={{ fontSize: 15, marginLeft: 10, marginTop: 0, marginBottom: 5 }}>{product.name} ({product.price} €) </h2>
                        <h3 style={{ fontSize: 13, marginLeft: 10, marginTop: 0 }}>Categorie: {getCategory(product)}</h3>
                    </div>
                </div>
            </td>
            <td><input type={'number'}  className="form-control" value={price} onChange={e => setPrice(e.target.value)} onBlur={update} /></td>
            <td><button onClick={() => removeProduct(resellerProduct)}><i class="fa fa-trash" /></button></td>
        </tr>
    )
}
function Product({ product, onClick }) {
    return (
        <div onClick={() => onClick(product)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', background: 'white', borderRadius: 5, boxShadow: ' 0 4px 8px 0 rgba(0,0,0,0.2)', marginTop: 15, padding: 10 }}>
            <div style={{}}>
                <img style={{ width: 50, height: 50, borderRadius: 75 }} src={STATIC.ROUTE(product.picture ? product.picture : '')} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ fontSize: 15, marginLeft: 10, marginTop: 0, marginBottom: 5 }}>{product.name} ({product.price} €) </h2>
                <h3 style={{ fontSize: 13, marginLeft: 10, marginTop: 0 }}>Categorie: {product.category.name}</h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                <i class="mdi mdi-chevron-right" style={{ fontSize: 20 }} ></i>
            </div>
        </div>
    )
}
function ResellerProduct({ reseller, resellerProducts, products }) {
    const [productList, setProductList] = React.useState(products);
    const [allProducts, setAllProducts] = React.useState(products);
    const [selectedProducts, setSelectedProducts] = React.useState(resellerProducts);
    const [allSelectedProducts, setAllSelectedProducts] = React.useState(resellerProducts);
    React.useEffect(() => {
        setProductList(products);
        setAllProducts(products);
    }, [products]);
    React.useEffect(() => {
        setSelectedProducts(resellerProducts);
    }, [resellerProducts]);
    const addProduct = product => {
        fetch(API.productReseller.add, {
            method: 'POST',
            body: JSON.stringify({
                reseller,
                product: product._id,
                price: product.price,
            }),
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(response => response.json())
            .then(
                productReseller => {
                    setSelectedProducts([{ ...productReseller, product }, ...allSelectedProducts])
                    setAllSelectedProducts([{ ...productReseller, product }, ...allSelectedProducts])
                });
    }
    const removeProduct = product => {
        fetch(API.productReseller.remove, {
            method: 'POST',
            body: JSON.stringify({
                id: product._id
            }),
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(response => response.json())
            .then(
                res => {
                    setSelectedProducts(allSelectedProducts.filter(el => el._id != product._id))
                    setAllSelectedProducts(allSelectedProducts.filter(el => el._id != product._id))
                }
            )
    }
    const checkProductExist = (product) => {
        for (const p of allSelectedProducts) {
            if (p.product._id === product._id) {
                return true;
            }
        }
        return false;
    }
    const filterReseller = (value) => {
        setSelectedProducts(allSelectedProducts.filter(el => el.product.name.toLowerCase().indexOf(value.target.value.toLowerCase()) !== -1))
    }
    const filterProduct = (value) => {
        setProductList(allProducts.filter(el => el.name.toLowerCase().indexOf(value.target.value.toLowerCase()) !== -1))
    }
    return (
        <div className="row col-sm-12">
            <div className="col-xl-3 col-md-4 col-sm-12" style={{ minHeight: 150, maxHeight: 400, overflowY: 'scroll', paddingBottom: 15 }}>
                <div className="row">
                    <div class="col-sm-12">
                        <h3>Liste des produits</h3>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group row">
                            <label for="name" className="col-sm-4 col-form-label">Recherche</label>
                            <div className="col-sm-8">
                                <input className="form-control" type="text" id="name" placeholder="Nom produit" onChange={filterProduct} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    productList.map(
                        product => checkProductExist(product) ? <div /> : <Product product={product} onClick={addProduct} />
                    )
                }
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12" style={{ minHeight: 150, maxHeight: 400, overflowY: 'scroll', borderRight: '1px solid #e9e9e9', borderLeft: '1px solid #e9e9e9' }}>
                <div className="row">
                    <div class="col-sm-12">
                        <h3>Carte Revendeur</h3>
                    </div>
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        
                        <div className="form-group row">
                            <label for="name" className="col-sm-2 col-form-label">Recherche</label>
                            <div className="col-sm-10">
                                <input className="form-control" type="text" id="name" placeholder="Nom produit" onChange={filterReseller} />
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th>Produit</th>
                            <th>Prix Revendeur</th>
                            <th>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedProducts.map(
                                product => <SelectedProduct resellerProduct={product} removeProduct={removeProduct} products={allProducts} />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ResellerProduct;