import React, { Component } from 'react'
import EditForm from '../../components/Sites/EditForm';
import { API, ROUTER } from '../../env';
import swal from 'sweetalert';
import { getI18n } from 'react-i18next';



export default class EditClient extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         id: this.props.match.params.id,
      }
    }
    componentWillMount(){
        console.log('ahahwa')
        fetch(API.sites.info(this.state.id), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            }
        })
            .then(res => res.json())
            .then(site => site.error ? window.location = ROUTER.ERROR : this.setState({site}))
            .catch(error => swal('Error', error.message, 'error'));
    }
    render() {
        const i18n = getI18n();
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                            <h4 className="page-title">{i18n.t('SITES')}</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">{i18n.t('EDIT_SITE')}</li>
                                </ol>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <EditForm site={this.state.site}/>
                </div>

            </div>
        )
    }
}

