import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import Moment from 'react-moment';
import { getI18n } from 'react-i18next';

class TableMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            messages: [],
            total: 0,
            count: 5,
            totalPage: 1,
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }
    nextPage() {
        let {page} = this.state;
        page++;
        this.setState({page});
        this.loadPage(page, this.state.name);
    }
    show(element){
        swal(element.subject, element.body, 'info');
    }
    prevPage() {
        let {page} = this.state;
        page--;
        this.setState({page});
        this.loadPage(page, this.state.name);
    }
    loadPage(page = 1, name='') {
        fetch(API.messages.list(page, this.state.count, name), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                this.setState({page, ...response});
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
    delete(id) {
        swal({
            title: this._.t('ARE_YOU_SURE'),
            text: this._.t('DELETE_WARNING'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              fetch(API.messages.remove, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'x-auth-token': localStorage.getItem('token'),
                      'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                  },
                  body: JSON.stringify({id})
              })
                .then(res => res.json())
                .then(response => {
                    if (response.error) {
                        return swal(this._.t('ERROR'), response.message, 'error');
                    }
                    return swal(this._.t('SUCCESS'), this._.t('DATA_REMOVED'), 'success').then(() => window.location = ROUTER.MESSAGES.LIST);
                })
                .catch(e => swal(this._.t('ERROR'), e.message, 'error'))
            }
          });
    }
    filter(event) {
        let searchValue = event.target.value;
        this.loadPage(1, searchValue);
    }
    render() {
        const renderContent = (elements) => elements.map(element => (
            <tr key={element._id}>
                <td>{element.firstName} {element.lastName}</td>
                <td>{element.email}</td>
                <td>{element.subject}</td>
                <td><Moment locale={'fr'} fromNow>{element.created_at}</Moment></td>
                <td>
                    <div className="button-items">
                        <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => this.show(element)}>{this._.t('SHOW_MORE_INFORMATION')}</button>
                        <button type="button" className="btn btn-outline-danger waves-effect waves-light" onClick={() => this.delete(element._id)}>{this._.t('DELETE')}</button>
                    </div>
                </td>
            </tr>
        ))
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="col-sm-12">
                                    <table className="table mb-0">

                                        <thead className="thead-light">
                                            <tr>
                                                <th>{this._.t('MESSAGE_SENDER')}</th>
                                                <th>{this._.t('MESSAGE_EMAIL')}</th>
                                                <th>{this._.t('MESSAGE_SUBJECT')}</th>
                                                <th>{this._.t('CREATED_AT')}</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderContent(this.state.messages)}
                                        </tbody>
                                    </table>
                                        
                                    </div>
                                    <div className="row" style={{marginTop: 15}}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.messages.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                            <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{'< Previous'}</button>
                                            <span style={{marginLeft: 15, marginRight: 15}}>{this.state.page} / {this.state.totalPage}</span>
                                            <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{'Next >'}</button>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TableMessage;