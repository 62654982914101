import React, { Component } from 'react'
import TableClients from '../../components/Clients/TableClients';
import { getI18n } from 'react-i18next';
import { ROUTER } from '../../env'
class ListClients extends Component {
    render() {
        const i18n = getI18n();
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{i18n.t('CLIENTS')}</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">{i18n.t('CLIENT_LIST')}</li>
                                </ol>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-0" style={{ float: "right" }}>
                                    <div>

                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light mr-1"
                                            onClick={() =>
                                                (window.location = ROUTER.CLIENTS.CREATE)
                                            }
                                        >
                                            {i18n.t("ADD_CLIENT_HEADER")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableClients />
                </div>

            </div>
        )
    }
}

export default ListClients;