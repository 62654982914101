import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next'
import ToggleButton from 'react-toggle-button'
import Backdrop from "../../components/Backdrop";
import CroppedImageModal from "../../components/CroppedImageModal/index";
import CroppedImage from "../../components/CroppedImage/index";
import './style.css';
import ResellerProduct from './ResellerProduct'
class EditForm extends Component {
    constructor(props) {
        super(props)
        this._ = getI18n();
        this.state = {
            id: this.props.client ? this.props.client._id : '',
            firstName: "",
            firstNameError: "",
            lastName: "",
            lastNameError: "",
            phone: "",
            phoneError: "",
            email: "",
            emailError: "",
            address: "",
            address2: "",
            city: "",
            country: "France",
            zipCode: "",
            reseller: false,
            file: "",
            imagePreviewUrl: null,
            imageToCrop: null,
            imgData: null,
            showBackdrop: false,
            picture: null,
            resellerProducts: [],
            products: [],
            enseigne: '',
        }
        this._ = getI18n();
    }

    handleChange(event) {
        const state = {}
        state[event.target.id] = event.target.value;
        this.setState(state);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.client) {
            this.setState({
                id: nextProps.client._id,
                firstName: nextProps.client.firstName ? nextProps.client.firstName : '',
                lastName: nextProps.client.lastName ? nextProps.client.lastName : '',
                phone: nextProps.client.phone ? nextProps.client.phone : '',
                email: nextProps.client.email ? nextProps.client.email : '',
                address: nextProps.client.address ? nextProps.client.address : '',
                address2: nextProps.client.address2 ? nextProps.client.address2 : '',
                city: nextProps.client.city ? nextProps.client.city : '',
                country: 'France',
                zipCode: nextProps.client.zipCode ? nextProps.client.zipCode : '',
                reseller: nextProps.client.reseller,
                picture: nextProps.client.picture,
                resellerProducts: nextProps.client.resellerProducts ? nextProps.client.resellerProducts : [],
                products: [],
                enseigne: nextProps.client.enseigne ? nextProps.client.enseigne : '',
            });
        }
    }

    componentDidMount() {
        window.$('.filestyle').filestyle({
            buttonName: 'btn-primary'
        });
        fetch(API.product.all, {
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(response => response.json())
            .then(
                products => {
                    this.setState({products});
                }
            )
    }
    onChangePicture = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    imgData: reader.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    handleChangeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    imageToCrop: reader.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        e.target.value = null;
    };
    cropChangedHandler = (image) => {
        let reader = new FileReader();
        let file = image;

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };
    fileChange = (e) => {
        this.handleChangeFile(e);
        window.$("#croppedImageModal").modal("show");
    };
    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    validate = () => {
        let firstNameError = "";
        let lastNameError = "";
        let emailError = "";
        let phoneError = "";
        // let passwordError = "";

        if (!this.state.firstName) {
            firstNameError = this._.t("CLIENT_FIRST_NAME_ERROR");
        }

        if (!this.state.lastName) {
            lastNameError = this._.t("CLIENT_LAST_NAME_ERROR");
        }
        if (!this.state.email) {
            emailError = this._.t("CLIENT_EMAIL_ERROR");
          } else {
            if(!this.validateEmail(this.state.email)){
              emailError = this._.t("CLIENT_EMAIL_NOT_VALID");
      
          }
          }

        if (!this.state.phone) {
            phoneError = this._.t("CLIENT_PHONE_ERROR");
        }
        if (firstNameError || lastNameError || emailError || phoneError) {
            this.setState({ firstNameError, lastNameError, emailError, phoneError });
            return false;
        }

        return true;
    };


    update() {
        const isValid = this.validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("firstName", this.state.firstName);
            formData.append("lastName", this.state.lastName);
            formData.append("phone", this.state.phone);
            formData.append("email", this.state.email);
            formData.append("address", this.state.address);
            formData.append("address2", this.state.address2);
            formData.append("city", this.state.city);
            formData.append("country", this.state.country);
            formData.append("zipCode", this.state.zipCode);
            formData.append("reseller", this.state.reseller);
            formData.append('enseigne', this.state.enseigne);
            if (this.state.file !== "") {
                formData.append("file", this.state.file, this.state.file.name);
              }



            fetch(API.clients.update(this.state.id), {
                method: 'POST',
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                    'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                },
                body: formData,
            })
                .then(res => res.json())
                .then(response => {
                    if (response.error) {
                        return swal(this._.t('ERROR'), response.message, 'error');
                    }
                    return swal(this._.t('SUCCESS'), this._.t('CLIENT_SAVED'), 'success')
                        .then(() => { window.location = ROUTER.CLIENTS.LIST });
                })
                .catch(e => swal(this._.t('ERROR'), this._.t('DATA_ERR'), 'error'));
        }
    }

    render() {
        const i18n = getI18n();
        console.log(this.state.category);
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title">{i18n.t('EDIT_CLIENT')}</h4>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_FIRST_NAME")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firstName"
                                        placeholder={i18n.t("CLIENT_FIRST_NAME_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.firstName}
                                    />
                                    <span className="form-error">
                                        {this.state.firstNameError}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_LAST_NAME")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="lastName"
                                        placeholder={i18n.t("CLIENT_LAST_NAME_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.lastName}
                                    />
                                    <span className="form-error">
                                        {this.state.lastNameError}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_ENSEIGNE")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="enseigne"
                                        placeholder={i18n.t("CLIENT_ENSEIGNE_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.enseigne}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label"></label>

                                {this.state.imagePreviewUrl === null ? (
                                    this.state.picture && (
                                        <img
                                            alt={this.state.firstName}
                                            src={STATIC.ROUTE(this.state.picture)}
                                            style={{ height: 100, margin: 15 }}
                                        />
                                    )
                                ) : (
                                        //<img src={images.accountProfile} alt="profile" />
                                        <img src={this.state.imagePreviewUrl} />
                                    )}
                                {/*
                                    props.state.logo ? (
                                        <img
                                        alt={props.state.name}
                                        src={STATIC.ROUTE(props.state.logo)}
                                        style={{ height: 100, margin: 15 }}
                                        />
                                    ) : (
                                        <div />
                                    )
                                    */}
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_IMAGE")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="file"
                                        className="filestyle"
                                        onChange={this.fileChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_PHONE")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="phone"
                                        placeholder={i18n.t("CLIENT_PHONE_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.phone}
                                    />
                                    <span className="form-error">
                                        {this.state.phoneError}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_EMAIL")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        placeholder={i18n.t("CLIENT_EMAIL_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.email}
                                    />
                                    <span className="form-error">
                                        {this.state.emailError}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_ADDRESS")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="address"
                                        placeholder={i18n.t("CLIENT_ADDRESS_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.address}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_ADDRESS2")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="address2"
                                        placeholder={i18n.t("CLIENT_ADDRESS2_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.address2}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_CITY")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="city"
                                        placeholder={i18n.t("CLIENT_CITY_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.city}
                                    />
                                </div>
                            </div>
                            {/*<div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_COUNTRY")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="country"
                                        placeholder={i18n.t("CLIENT_COUNTRY_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.country}
                                    />
                                </div>
                                </div>*/}
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_ZIPCODE")}
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="zipCode"
                                        placeholder={i18n.t("CLIENT_ZIPCODE_PH")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.zipCode}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">
                                    {i18n.t("CLIENT_RESSELER")}
                                </label>
                                <div className="col-sm-10">
                                    <ToggleButton
                                        inactiveLabel={
                                            <div style={{ textAlign: "center" }}>
                                                {i18n.t("NO")}

                                            </div>
                                        }
                                        activeLabel={
                                            <div style={{ textAlign: "center" }}>
                                                {i18n.t("YES")}

                                            </div>
                                        }
                                        value={this.state.reseller}
                                        onToggle={(reseller) => {
                                            this.setState({
                                                reseller: !reseller,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                this.state.reseller ? 
                                    <div className="form-group row">
                                        <ResellerProduct reseller={this.state.id} resellerProducts={this.state.resellerProducts} products={this.state.products}/>
                                    </div>
                                :
                                <div/>
                            }
                            <div className="form-group mb-5" style={{ float: 'right' }}>
                                <div>
                                    <button type="reset" className="btn btn-secondary waves-effect  mr-1" onClick={() => window.location = ROUTER.CLIENTS.LIST}>{i18n.t('CANCEL')}</button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" onClick={this.update.bind(this)}>{i18n.t('SUBMIT')}</button>
                                </div>
                            </div>
                        
                            {this.state.showBackdrop && (
                                <Backdrop backdropClick={this.handleShowModal} />
                            )}
                            <CroppedImageModal
                                fileChangedHandler={this.cropChangedHandler}
                                file={this.state.file}
                            >
                                <CroppedImage
                                    src={this.state.imageToCrop}
                                    setFile={(file) => this.setState({ file: file })}
                                    logo={true}
                                />
                            </CroppedImageModal>
                           
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditForm;