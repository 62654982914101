import React, { Component } from 'react'
import ListProducts from '../../components/TodayMenu/ListProducts';

class ListMenus extends Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid" style={{paddingTop: '2%'}}>
                    <ListProducts />
                </div>

            </div>
        )
    }
}

export default ListMenus;