import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import reducers from './reducers';
import App from './components/App/App';
import { useTranslation } from 'react-i18next'

const P = () => {
    useTranslation();
    const createStoreWithMiddleware = applyMiddleware(promiseMiddleware)(createStore);
    return (
        <Provider store={createStoreWithMiddleware(reducers)}>
            <App />
        </Provider>
    )
}
export default P;