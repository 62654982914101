import React from 'react'
import { API } from '../../env';
import { getI18n } from 'react-i18next';
import swal from 'sweetalert'

export default function Payzen(props) {
    const [mode, setMode] = React.useState('TEST');
    const [certif, setCertif] = React.useState('');
    const [siteId, setSiteId] = React.useState('');
    const [vadsVersion, setVadsVersion] = React.useState('v2');
    React.useEffect(() => {
        fetch(API.settings.load, {
            method: 'POST',
            body: JSON.stringify({ key: 'PAYZEN' }),
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response) {
                    setMode(response.mode);
                    setCertif(response.certif);
                    setSiteId(response.siteId);
                    setVadsVersion(response.vadsVersion);
                }
            })
            .catch(e => {

            })
    }, []);
    const update = () => {
        fetch(API.settings.update, {
            method: 'POST',
            body: JSON.stringify({ key: 'PAYZEN', value: { mode, certif, siteId, vadsVersion } }),
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                return swal(i18n.t('SUCCESS'), i18n.t('DATA_UPDATED'), 'success');
            })
            .catch(e => {

            })
    }
    const i18n = getI18n();
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h4 className="page-title">{i18n.t('PAYMENT_SYSTEM')}</h4>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group row">
                                    <label for="firstName" className="col-sm-2 col-form-label">Mode Payzen</label>
                                    <div className="col-sm-10">
                                        <select onChange={event => setMode(event.target.value)} className="form-control">
                                            <option value="TEST" selected={mode === "TEST"}>Test</option>
                                            <option value="PRODUCTION" selected={mode === "PRODUCTION"}>Production</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="firstName" className="col-sm-2 col-form-label">Clé Certification</label>
                                    <div className="col-sm-10">
                                        <input className="form-control" type="text" value={certif} id="firstName" placeholder="Clé certification payzen ..." onChange={event => setCertif(event.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="firstName" className="col-sm-2 col-form-label">Site Id</label>
                                    <div className="col-sm-10">
                                        <input className="form-control" type="text" value={siteId} id="firstName" placeholder="Site Id payzen ..." onChange={event => setSiteId(event.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="firstName" className="col-sm-2 col-form-label">Version Payzen</label>
                                    <div className="col-sm-10">
                                        <select onChange={event => setVadsVersion(event.target.value)} className="form-control">
                                        <option value="V2" selected={vadsVersion === "V2"}>Version 2</option>
                                        <option value="V1" selected={vadsVersion === "V1"}>Version 1</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group mb-0" style={{ float: 'right' }}>
                                    <div>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={update}>{i18n.t('SUBMIT')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
