import React, { Component } from 'react'
import AddForm from '../../components/Products/AddForm';
import { getI18n } from 'react-i18next';

export default class AddProduct extends Component {
    render() {
        const i18n = getI18n();
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{i18n.t('PRODUCT')}</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">{i18n.t('ADD_PRODUCT_HEADER')}</li>
                                </ol>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <AddForm/>
                </div>

            </div>
        )
    }
}
