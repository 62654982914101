import React, { Component } from "react";
import { ROUTER } from "../../env";
import { getI18n } from "react-i18next";

export default class SideBar extends Component {
  componentDidMount() {
    //window.$.MainApp.init();
  }
  render() {
    const i18n = getI18n();
    return (
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          <div id="sidebar-menu">
            <ul className="metismenu" id="side-menu">
              <li>
                <a href={ROUTER.HOME} className="waves-effect">
                  <i className="ti-home"></i>
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a _blank="true" className="waves-effect">
                  <i className="mdi mdi-format-list-bulleted-type"></i>
                  <span>
                    {i18n.t("CATEGORY")}
                    <span className="float-right menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </span>
                </a>
                <ul className="submenu">
                  <li>
                    <a href={ROUTER.CATEGORIES.CREATE}>
                      <i className="ti-pencil-alt"></i>
                      <span>{i18n.t("ADD_CATEGORY")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.CATEGORIES.LIST}>
                      <i className="ti-list"></i>
                      <span>{i18n.t("LIST_CATEGORY")}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a _blank="true" className="waves-effect">
                  <i className="mdi mdi-bowl"></i>
                  <span>
                    {i18n.t("PRODUCT")}
                    <span className="float-right menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </span>
                </a>
                <ul className="submenu">
                  <li>
                    <a href={ROUTER.PRODUCTS.CREATE}>
                      <i className="ti-pencil-alt"></i>
                      <span>{i18n.t("ADD_PRODUCT")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.PRODUCTS.LIST}>
                      <i className="ti-list"></i>
                      <span>{i18n.t("LIST_PRODUCT")}</span>
                    </a>
                  </li>

                  <li>
                    <a href={ROUTER.OPTIONS.LIST}>
                      <i className="ti-list"></i>
                      <span>{i18n.t("LIST_OPTION")}</span>
                    </a>
                  </li>

                  <li>
                    <a href={ROUTER.SUPPLEMENTS.LIST}>
                      <i className="ti-list"></i>
                      <span>{i18n.t("LIST_SUPPLEMENT")}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a _blank="true" className="waves-effect">
                  <i className="mdi mdi-library-books"></i>
                  <span>
                    {i18n.t("ORDERS")}
                    <span className="float-right menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </span>
                </a>
                <ul className="submenu">
                  <li>
                    <a href={ROUTER.ORDERS.ONHOLD}>
                      <i className="ti-pencil-alt"></i>
                      <span>{i18n.t("ON_HOLD_ORDERS")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.ORDERS.LIST}>
                      <i className="ti-list"></i>
                      <span>{i18n.t("ALL_ORDERS")}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={ROUTER.MENUS.LIST} className="waves-effect">
                  <i className="mdi mdi-calendar"></i>
                  <span>
                    {i18n.t("MENU")}
                    <span className="float-right menu-arrow"></span>
                  </span>
                </a>
              </li>
              <li>
                <a href={ROUTER.MESSAGES.LIST} className="waves-effect">
                  <i className="mdi mdi-message"></i>
                  <span>{i18n.t("MESSAGES")}</span>
                </a>
              </li>

              <li>
                <a _blank="true" className="waves-effect">
                  <i className="mdi mdi-bowl"></i>
                  <span>
                    {i18n.t("SETTINGS")}
                    <span className="float-right menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </span>
                </a>
                <ul className="submenu">
                  <li>
                    <a href={ROUTER.SETTINGS} className="waves-effect">
                      <i className="mdi mdi-settings"></i>
                      <span>{i18n.t("GENERAL")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.DELIVERY} className="waves-effect">
                      <i className="mdi mdi-settings"></i>
                      <span>{i18n.t("DELIVERY")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.CONTENT} className="waves-effect">
                      <i className="mdi mdi-settings"></i>
                      <span>{i18n.t("CONTENT_MANAGEMENT")}</span>
                    </a>
                  </li>
                  <li>
                    <a href={ROUTER.PAYZEN} className="waves-effect">
                      <i className="mdi mdi-settings"></i>
                      <span>{i18n.t("PAYMENT_SYSTEM")}</span>
                    </a>
                  </li>
                 
                </ul>
              </li>
              <li>
                <a href={ROUTER.CLIENTS.LIST} className="waves-effect">
                  <i className="fa fa-user"></i>
                  <span>{i18n.t("CLIENTS")}</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    localStorage.clear();
                    window.location = ROUTER.HOME;
                  }}
                  className="waves-effect"
                >
                  <i className="mdi mdi-logout-variant"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}
