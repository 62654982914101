import React, { useState, useRef } from "react";
import { Map, GoogleApiWrapper, Marker, Circle } from "google-maps-react";
import Geocode from "react-geocode";

const mapStyles = {
  width: "100%",
  height: "480px",
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "480px",
};
const MapComponent = (props) => {
  console.log('[MAP] - ', props);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [google, setGoogle] = useState(props.google);
  const [distance, setDistance] = useState(props.distance);
  React.useEffect(() => {
    console.log('Mapping: ', props);
    if (props.lat) {
      setLat(props.lat);
    }
    if (props.lng) {
      setLng(props.lng);
    }
    if (props.distance) {
      setDistance(props.distance);
    }
  }, [props]);
  const onMarkerDragEnd = async (coord, index) => {
    Geocode.setApiKey('AIzaSyCDJ_8QPJUFbK_v_CAeY3J1T9ehvMoJq2A');
    Geocode.setLanguage("fr");
    Geocode.enableDebug();

    //this.props.setLocation(coord);
    const { latLng } = coord;
    let formatedAdress = "";
    await Geocode.fromLatLng(latLng.lat(), latLng.lng()).then(
      (response) => {
        console.log(response);
        const address = response.results[0].formatted_address;
        console.log(address);
        formatedAdress = address;
      },
      (error) => {
        console.error('eeer', error);
      }
    );
    setLat(latLng.lat());
    setLng(latLng.lng());
    props.setLocation(latLng.lat(), latLng.lng(), formatedAdress);
  }
  return (<Map
    google={google}
    zoom={props.zoom ? props.zoom : 9}
    style={mapStyles}
    containerStyle={containerStyle}
    initialCenter={{
      lat: props.lat,
      lng: props.lng,
    }}
    center={{
      lat,
      lng,
    }}
  >
   <Marker
          id={1}
           position={{
             lat, lng
           }}
           draggable={props.drag ? true : false}
           onDragend={(t, map, coord) => onMarkerDragEnd(coord, '')}
         ></Marker>
      <Circle
        radius={distance * 1000}
        center={{
          lat: Number(lat),
          lng: Number(lng),
        }}
        onMouseover={() => console.log("mouseover")}
        onClick={() => console.log("click")}
        onMouseout={() => console.log("mouseout")}
        strokeColor="transparent"
        strokeOpacity={0}
        strokeWeight={5}
        fillColor="#FF0000"
        fillOpacity={0.2}
      />

  </Map>);
}
// class MapComponent extends React.Component {
//   render() {
//     const props = this.props;
//     return (
//       <Map
//         google={props.google}
//         zoom={props.zoom ? props.zoom : 9}
//         style={mapStyles}
//         containerStyle={containerStyle}
//         initialCenter={{
//           lat: this.state.lat,
//           lng: this.state.lng,
//         }}
//         center={{
//           lat: this.state.lat,
//           lng: this.state.lng,
//         }}
//       >
//         <Marker
//           title="Location"
//           id={1}
//           position={{
//             lat: this.state.lat,
//             lng: this.state.lng,
//           }}
//           draggable={this.props.drag ? true : false}
//           onDragend={(t, map, coord) => {
//             this.onMarkerDragEnd(coord);
//           }}
//         ></Marker>
//         {this.props.delivery && (
//           <Circle
//             radius={this.state.distance}
//             center={{
//               lat: Number(this.state.lat),
//               lng: Number(this.state.lng),
//             }}
//             onMouseover={() => console.log("mouseover")}
//             onClick={() => console.log("click")}
//             onMouseout={() => console.log("mouseout")}
//             strokeColor="transparent"
//             strokeOpacity={0}
//             strokeWeight={5}
//             fillColor="#FF0000"
//             fillOpacity={0.2}
//           />
//         )}
//       </Map>
//     );
//   }
// }

export default GoogleApiWrapper({apiKey: "AIzaSyCDJ_8QPJUFbK_v_CAeY3J1T9ehvMoJq2A"})(MapComponent);
