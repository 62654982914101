import React, { Component } from 'react'
import {ROUTER, STATIC} from '../../env';
export default class TopBar extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            sites: [],
            currentSite: undefined,
        }
        this.handleSiteChange = this.handleSiteChange.bind(this);
    }

    componentWillMount(){
        const sites = JSON.parse(localStorage.getItem('user')).sites;
        const currentSite = JSON.parse(localStorage.getItem('currentSite'));
        this.setState({sites, currentSite});
    }
    componentDidMount(){
	    window.$.MainApp.init();
    }
    logout(){
        localStorage.clear();
        window.location = ROUTER.LOGIN;
    }
    handleSiteChange(event){
        const newSiteId = event.target.value;
        const currentSite = this.state.sites.filter(element => element._id === newSiteId)[0];
        localStorage.setItem('currentSite', JSON.stringify(currentSite));
        window.location.reload();
    }
    render() {
        return (
            <div className="topbar">
                <div className="topbar-left">
                    <a href={ROUTER.HOME} className="logo">
                        <span>
                            <img src={STATIC.LOGO} alt="I-order" style={{height: 50}} />
                        </span>
                        <i>
                            <img src={STATIC.ICON} alt="I-Order small logo" style={{width: '100%', height: 'auto', padding: 10}} />
                        </i>
                    </a>
                </div>

                <nav className="navbar-custom">
                    <ul className="navbar-right list-inline float-right mb-0">
                        <li className="dropdown notification-list list-inline-item">
                            <a href={ROUTER.SITES}><i className="mdi mdi-settings" style={{fontSize: 22, marginRight: 10}}></i></a>
                        </li>
                        <li className="dropdown notification-list list-inline-item">
                            <select className="form-control" onChange={this.handleSiteChange} defaultValue={this.state.currentSite._id}>
                                {this.state.sites.map(site => <option key={site._id} value={site._id}>{site.name}</option>)}
                            </select>
                        </li>
                        <li className="dropdown notification-list list-inline-item">
                            <div className="dropdown notification-list nav-pro-img">
                                <button style={{border: 'none', background: 'transparent'}} className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                    <img src="/assets/images/users/user-4.jpg" alt="user" className="rounded-circle" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                    <button className="dropdown-item text-danger" onClick={this.logout.bind(this)}><i className="mdi mdi-power text-danger"></i> Logout</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-inline menu-left mb-0">
                        <li className="float-left">
                            <button className="button-menu-mobile open-left waves-effect">
                                <i className="mdi mdi-menu"></i>
                            </button>
                        </li>
                    </ul>

                </nav>

            </div>

        )
    }
}
