import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next';

class TableSites extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            sites: [],
            total: 0,
            count: 5,
            totalPage: 1,
            name: ''
        }
        this._ = getI18n();
    }
    componentDidMount() {
        this.loadPage();
    }

    nextPage() {
        let { page } = this.state;
        page++;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    prevPage() {
        let { page } = this.state;
        page--;
        this.setState({ page });
        this.loadPage(page, this.state.name);
    }
    loadPage(page = 1, name = '') {
        fetch(API.sites.list(page, this.state.count), {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal('Error', response.message, 'error');
                }
                this.setState({ page, sites: response });
            })
            .catch(e => swal('Error', e.message, 'error'));
    }
  
    filter(event) {
        let name = event.target.value;
        this.loadPage(1, name);
        this.setState({name})
    }
    render() {
        const renderContent = (elements) => elements.map(element => {

            return (
                <tr key={element._id}>
                    <td style={{ padding: 15 }}>
                        <div style={{ height: 75 }}>
                            <img alt={`${element.name}`} src={STATIC.ROUTE(element.picture ? element.picture : '/')} style={{ height: '100%', width: 'auto' }} />
                        </div>
                    </td>
                    <td>{element.name}</td>
                    <td>
                        <div className="button-items">
                            <button type="button" className="btn btn-outline-info waves-effect waves-light" onClick={() => window.location = ROUTER.SITES_EDIT(element._id)}>{this._.t('EDIT')}</button>
                        </div>
                    </td>
                </tr>
            )
        }
        )
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                            <div className="form-group row">
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-5">
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <table className="table mb-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{this._.t('SITES_IMAGE')}</th>
                                                    <th>{this._.t('SITES_NAME')}</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderContent(this.state.sites)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-12 col-md-6 cold-log-8">
                                            <span>Showing : {((this.state.page - 1) * this.state.count) + this.state.sites.length} / {this.state.total} element(s) </span>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-6 offset-lg-8">
                                            <div className="col-sm-12">
                                                <button onClick={this.prevPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page === 1}>{'< Previous'}</button>
                                                <span style={{ marginLeft: 15, marginRight: 15 }}>{this.state.page} / {this.state.totalPage}</span>
                                                <button onClick={this.nextPage.bind(this)} type="button" className="btn col-sm-4 btn-outline-info waves-effect waves-light" disabled={this.state.page >= this.state.totalPage}>{'Next >'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TableSites;