import React, { Component } from 'react'
import { API, STATIC } from '../../env'
import moment from 'moment';
import swal from 'sweetalert';
import 'moment/min/moment-with-locales'
import 'moment/locale/fr';

import './Style.css'
import { getI18n } from 'react-i18next';

function SelectedProduct(props) {
    const {product} = props;
    return (
        <div className={'product'}>
            <div class="product-cover" style={{backgroundImage: `url('${STATIC.ROUTE(product.picture ? product.picture : '/')}')`}} />
            <div class="product-details">
                <h3 class="product-title">{product.name}</h3>
                <p class="product-description" dangerouslySetInnerHTML={{__html: product.description ? product.description : ''}}/>
                <hr/>
            </div>
            <div class="product-footer ">
                <span class="price">{product.price}€</span>
                <button class="product-remove" onClick={props.remove}>
                    <i class="mdi  mdi-bookmark-remove"></i>
                </button>
            </div>
        </div>
    );
}

class DayForm extends Component {
    constructor(props) {
        super(props)
        this.productsDiv = [];
        this.state = {
            id: this.props.content ?  this.props.content._id : null,
            products: this.props.content ? this.props.content.products : [],
            name: this.props.content.name,
            date: this.props.day.format('YYYY-MM-DD'),
        }
        this._ = getI18n();
    }
    componentWillReceiveProps(n) {
        let { content, day } = n;
        if (day) {
            this.setState({date: day.format('YYYY-MM-DD')});
        }
        if (content) {
            this.setState({ products: content.products, name: content.name, id: content._id });
        }
    }
    componentDidUpdate(){
        if(this.productsDiv.length > 0){
            this.productsDiv[this.productsDiv.length - 1].scrollIntoView({ behavior: "smooth" });
        }
    }
    publish() {
        fetch(API.menu.publishWeek, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
            body: JSON.stringify(this.state)
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal(this._.t('ERROR'), response.message, 'error');
                }
                return swal(this._.t('SUCCESS'), this._.t('DATA_SAVED_MENU'), 'success');
            })
            .catch(e => swal(this._.t('ERROR'), this._.t('DATA_ERR'), 'error'));
    }
    handleChange(event) {
        let name = event.target.value;
        this.props.updateMenu(name, this.props.dow);
    }
    render() {
        const i18n = getI18n();
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('MENU_NAME')}</label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="text" id="name" value={this.state.name} placeholder={i18n.t('MENU_NAME')} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="activeProductList">
                                {this.state.products.length > 0 ?
                                    this.state.products.map(product => {
                                        return <SelectedProduct key={product._id} product={product} remove={() => this.props.removeProduct(product, this.props.dow)} />
                                    })
                                    :
                                <h1>{i18n.t('NO_MENU_TODAY')}</h1>}
                            </div>
                            <div className="form-group mb-0" style={{ float: 'right', marginTop: 15 }}>
                                <div>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" onClick={this.publish.bind(this)}>{i18n.t('PUBLISH_MENU')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class Tabs extends React.Component {
    state = {
        activeTab: this.props.activeTab ? this.props.activeTab : this.props.children[0].props.label
    }
    changeTab = (tab, dow) => {
        this.setState({ activeTab: tab });
        this.props.changeDow(dow);
    };
    render() {

        let content;
        let buttons = [];
        return (
            <div>
                {React.Children.map(this.props.children, child => {
                    buttons.push({ name: child.props.label, date: child.props.date })
                    if (child.props.label === this.state.activeTab) content = child.props.children
                })}

                <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
                <div className="tab-content">{content}</div>

            </div>
        );
    }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {

    return (
        <div className="tab-buttons">
            {buttons.map(button => {
                return <button key={button.name} className={button.name === activeTab ? 'active' : ''} onClick={() => changeTab(button.name, moment(button.date, 'DD/MM/YYYY').weekday())}>{button.name}<p style={{ padding: 0, margin: 0, fontSize: 13, fontWeight: '300', color: '#9c9c9c' }}>{button.date}</p></button>
            })}
        </div>
    )
}

const Tab = props => {
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

class ProductItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: this.props.product
        }
    }
    componentWillReceiveProps(np) {
        const { product } = np;
        if (product) {
            this.setState({ product })
        }
    }
    render() {
        const { product } = this.state;
        return (
            <div onClick={() => this.props.onClick(product)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', background: 'white', borderRadius: 5, boxShadow: ' 0 4px 8px 0 rgba(0,0,0,0.2)', marginTop: 15, padding: 10 }}>
                <div style={{}}>
                    <img style={{ width: 50, height: 50, borderRadius: 75 }} src={STATIC.ROUTE(product.picture ? product.picture : '')} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ fontSize: 15, marginLeft: 10, marginTop: 0, marginBottom: 5 }}>{product.name} ({product.price} €) </h2>
                    <h3 style={{ fontSize: 13, marginLeft: 10, marginTop: 0 }}>Categorie: {product.category.name}</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                    <i class="mdi mdi-chevron-right" style={{ fontSize: 20 }} ></i>
                </div>
            </div>
        )
    }
}

export default class ListProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [],
            dow: moment().weekday(),
            menus: [
                { 
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                },
                {
                    _id: null,
                    name: '',
                    products: [],
                }]
        }
        this.itemClick = this.itemClick.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.filterProducts = this.filterProducts.bind(this);
        this.loadMenu();
    }
    componentDidMount() {
        fetch(API.menu.product, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(res => res.json())
            .then(products => this.setState({ products }))
            .catch(console.log);
    }
    loadMenu() {
        fetch(API.menu.getWeek, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            }
        })
            .then(res => res.json())
            .then(menus => {
                this.setState({menus})
            })
            .catch(console.log);
    }
    itemClick(product) {
        let {menus, dow} = this.state;
        menus[dow].products.push(product);
        this.setState({menus});
    }
    removeProduct(product, dow) {
        let products = this.state.menus[dow].products;
        let index = this.productIndex(products, product);
        products.splice(index, 1);
        let menus = this.state.menus;
        menus[dow].products = products;
        this.setState({menus});
    }
    updateMenu(name, dow) {
        let menus = this.state.menus;
        menus[dow].name = name;
        this.setState({menus});
    }
    productIndex(products, product) {
        let index = -1;
        for(let i = 0; i< products.length; i++) {
            if(products[i]._id === product._id) {
                return i;
            }
        }
        return index;
    }
    filterProducts(name) {
        fetch(`${API.menu.product}?name=${name.target.value}`, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
                'x-site-id': JSON.parse(localStorage.getItem('currentSite'))._id,
            },
        })
            .then(res => res.json())
            .then(products => this.setState({ products }))
            .catch(console.log);
    }
    render() {
        return (
            <div class="row">
                <div class="col-md-4 col-lg-3 col-sm-6" style={{ margin: 0, padding: 0, height: '70vh' }}>
                    <div class="col-sm-12" style={{ background: '#fff', borderRadius: '20px 20px 0px 0px', padding: 10 }}>
                        <h1 style={{ fontSize: 15, textAlign: 'center', padding: 8 }}>List des produits</h1>
                        <input type="text" placeholder="Chercher un produit..." className="form-control" onChange={this.filterProducts}/>
                    </div>
                    <div class="col-sm-12" style={{ border: '1px solid #ececf1', overflowY: 'scroll', height: '90%' }}>
                        {this.state.products.map(product => {
                            return this.productIndex(this.state.menus[this.state.dow].products, product) === -1 ? <ProductItem key={product._id} product={product} onClick={this.itemClick} /> : <div/>
                        })}
                    </div>
                </div>
                <div class="col-md-7 col-lg-8 col-sm-5" style={{ marginLeft: 15, background: 'white' }}>
                    <Tabs changeDow={(dow) => this.setState({dow, menus: this.state.menus})} activeTab={moment().format('dddd')}>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(0).format('dddd')} date={moment().weekday(0).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(0)} content={this.state.menus[0]} dow={0} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(1).format('dddd')} date={moment().weekday(1).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(1)} content={this.state.menus[1]} dow={1} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(2).format('dddd')} date={moment().weekday(2).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(2)} content={this.state.menus[2]} dow={2} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(3).format('dddd')} date={moment().weekday(3).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(3)} content={this.state.menus[3]} dow={3} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(4).format('dddd')} date={moment().weekday(4).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(4)} content={this.state.menus[4]} dow={4} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(5).format('dddd')} date={moment().weekday(5).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(5)} content={this.state.menus[5]} dow={5} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                        <Tab setDate={this.setActiveDate} label={moment().weekday(6).format('dddd')} date={moment().weekday(6).format('DD/MM/YYYY')}>
                            <DayForm day={moment().weekday(6)} content={this.state.menus[6]} dow={6} updateMenu={this.updateMenu} removeProduct={this.removeProduct}/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}
