import React from "react";
import "./style.css";
import { getI18n } from "react-i18next";
import HomePage from "./HomePage";
import MenuPage from "./MenuPage";
import ContactPage from "./ContactPage";
import AboutPage from "./AboutPage";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import ResetPage from "./ResetPage";
import GeneralPage from "./GeneralPage";
import CheckoutPage from "./CheckoutPage";
import CartPage from "./CartPage";
import SlidesPage from "./Slides/SlidesPage";
function Pages() {
  const _ = getI18n();
  return (
    <div className="row">
      <div className="col-3 border-container">
        <h5 className="style-title">{_.t("PAGE_TITLE")}</h5>

        <div
          className="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {/*
            <a
            className="nav-link"
            id="v-pills-general-tab"
            data-toggle="pill"
            href="#v-pills-general"
            role="tab"
            aria-controls="v-pills-general"
            aria-selected="false"
          >
            {_.t("GENERAL_CONTENT")}
          </a>
            
            */}

          <a
            className="nav-link active"
            id="v-pills-home-tab"
            data-toggle="pill"
            href="#v-pills-home"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            {_.t("HOME")}
          </a>
          <a
            className="nav-link"
            id="v-pills-slides-tab"
            data-toggle="pill"
            href="#v-pills-slides"
            role="tab"
            aria-controls="v-pills-slides"
            aria-selected="false"
          >
            {_.t("SLIDES")}
          </a>

          <a
            className="nav-link"
            id="v-pills-menu-tab"
            data-toggle="pill"
            href="#v-pills-menu"
            role="tab"
            aria-controls="v-pills-menu"
            aria-selected="false"
          >
            {_.t("MENU_CONTENT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-contact-tab"
            data-toggle="pill"
            href="#v-pills-contact"
            role="tab"
            aria-controls="v-pills-contact"
            aria-selected="false"
          >
            {_.t("CONTACT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-about-tab"
            data-toggle="pill"
            href="#v-pills-about"
            role="tab"
            aria-controls="v-pills-about"
            aria-selected="false"
          >
            {_.t("ABOUT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-login-tab"
            data-toggle="pill"
            href="#v-pills-login"
            role="tab"
            aria-controls="v-pills-login"
            aria-selected="false"
          >
            {_.t("LOGIN_CONTENT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-register-tab"
            data-toggle="pill"
            href="#v-pills-register"
            role="tab"
            aria-controls="v-pills-register"
            aria-selected="false"
          >
            {_.t("REGISTER_CONTENT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-reset-tab"
            data-toggle="pill"
            href="#v-pills-reset"
            role="tab"
            aria-controls="v-pills-reset"
            aria-selected="false"
          >
            {_.t("RESET_CONTENT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-checkout-tab"
            data-toggle="pill"
            href="#v-pills-checkout"
            role="tab"
            aria-controls="v-pills-checkout"
            aria-selected="false"
          >
            {_.t("CHECKOUT_CONTENT")}
          </a>
          <a
            className="nav-link"
            id="v-pills-cart-tab"
            data-toggle="pill"
            href="#v-pills-cart"
            role="tab"
            aria-controls="v-pills-cart"
            aria-selected="false"
          >
            {_.t("CART_CONTENT")}
          </a>
        </div>
      </div>
      <div className="col-9 border-container">
        <h5 className="style-title">{_.t("CONTENT_TITLE")}</h5>
        <div className="tab-content" id="v-pills-tabContent">
          {/*

<div
            className="tab-pane fade"
            id="v-pills-general"
            role="tabpanel"
            aria-labelledby="v-pills-general-tab"
          >
            <GeneralPage />
          </div>
            */}

          <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <HomePage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-slides"
            role="tabpanel"
            aria-labelledby="v-pills-slides-tab"
          >
            <SlidesPage />
          </div>

          <div
            className="tab-pane fade"
            id="v-pills-menu"
            role="tabpanel"
            aria-labelledby="v-pills-menu-tab"
          >
            <MenuPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-contact"
            role="tabpanel"
            aria-labelledby="v-pills-contact-tab"
          >
            <ContactPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-about"
            role="tabpanel"
            aria-labelledby="v-pills-about-tab"
          >
            <AboutPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-login"
            role="tabpanel"
            aria-labelledby="v-pills-login-tab"
          >
            <LoginPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-register"
            role="tabpanel"
            aria-labelledby="v-pills-register-tab"
          >
            <RegisterPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-reset"
            role="tabpanel"
            aria-labelledby="v-pills-reset-tab"
          >
            <ResetPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-checkout"
            role="tabpanel"
            aria-labelledby="v-pills-checkout-tab"
          >
            <CheckoutPage />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-cart"
            role="tabpanel"
            aria-labelledby="v-pills-cart-tab"
          >
            <CartPage />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pages;
