import React, { Component } from 'react'
import TableOptions from '../../components/Option/TableOptions';
import { getI18n } from 'react-i18next';
import { ROUTER } from "../../env";

class ListOptions extends Component {
  render() {
    const i18n = getI18n();
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">{i18n.t('OPTION')}</h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">{i18n.t('OPTION_LIST')}</li>
                </ol>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-0" style={{ float: "right" }}>
                  <div>

                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light mr-1"
                      onClick={() =>
                        (window.location = ROUTER.OPTIONS.CREATE)
                      }
                    >
                      {i18n.t("ADD_OPTION_HEADER")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableOptions />
        </div>

      </div>
    )
  }
}

export default ListOptions;