import React, { Component } from 'react'
import { API, ROUTER } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next'

class AddForm extends Component {
    constructor(props) {
      super(props)
      this._ = getI18n();
      this.state = {
         name: '',
         parent: undefined,
         parents: [],
         displayOrder: 0,
      }
      this.selectParent = this.selectParent.bind(this);
    }
    componentDidMount(){

        window.$('.filestyle').filestyle({
            buttonName: 'btn-primary'
        });
        fetch(API.category.all, {
            method: 'GET',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            }
        })
            .then(res => res.json())
            .then(parents => this.setState({parents}))
            .catch(e => swal('Erreur', e.message, 'error'));
    }
    create() {
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('displayOrder', this.state.displayOrder);
        formData.append('parent', this.state.parent ? this.state.parent.value : null);
        if (window.$('.filestyle')[0].files && window.$('.filestyle')[0].files.length > 0) {
            formData.append('file', window.$('.filestyle')[0].files[0], 'file');
        }
        fetch(API.category.create, {
            method: 'POST',
            headers: {
                'x-auth-token': localStorage.getItem('token'),
            },
            body: formData,
        })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    return swal(this._.t('ERROR'), response.message, 'error');
                }
                return swal(this._.t('SUCCESS'), this._.t('DATA_SAVED'), 'success').then(() => {window.location = ROUTER.CATEGORIES.LIST});
            })
            .catch(e => swal(this._.t('ERROR'), this._.t('DATA_ERR'), 'error'));
    }
    handleChange(event){
        const state = {}
        state[event.target.id] = event.target.value;
        this.setState(state);
    }
    selectParent(parent) {
        this.setState({parent})
    }
    render() {
        const i18n = getI18n();
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_NAME')}</label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="text" id="name" placeholder={i18n.t('CATEGORY_NAME_PH')} onChange={this.handleChange.bind(this)}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_IMAGE')}</label>
                                <div className="col-sm-10">
                                    <input type="file" className="filestyle" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">{i18n.t('CATEGORY_DISPLAY_ORDER')}</label>
                                <div className="col-sm-10">
                                    <input className="form-control" min={0} type="number" id="displayOrder" placeholder={i18n.t('CATEGORY_DISPLAY_ORDER_PH')} onChange={this.handleChange.bind(this)}/>
                                </div>
                            </div>
                            <div className="form-group mb-0" style={{float: 'right'}}>
                                <div>
                                    <button type="reset" className="btn btn-secondary waves-effect  mr-1" onClick={() => window.location = ROUTER.CATEGORIES.LIST}>{i18n.t('CANCEL')}</button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-1" onClick={this.create.bind(this)}>{i18n.t('SUBMIT')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddForm;