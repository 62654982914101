import React, { Component } from 'react'
import { API, ROUTER, STATIC } from '../../env'
import swal from 'sweetalert'
import { getI18n } from 'react-i18next'
import Select from 'react-select'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ToggleButton from 'react-toggle-button'

class EditForm extends Component {
  constructor(props) {
    super(props);
    this._ = getI18n();
    
  }
    componentDidMount() {
        window.$('.filestyle').filestyle({
            buttonName: 'btn-primary'
        });

       
   
        }

    

    render() {
        const i18n = getI18n();
        return (
            <div className="row">
            <div className="col-12">
              
              <div className="card">
                <div className="card-body">
                  
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {i18n.t("SUPPLEMENT_NAME")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        placeholder={i18n.t("SUPPLEMENT_NAME_PH")}
                        onChange={this.props.handleChange}
                        value={this.props.state.name}
                      />
                       <span className="text-danger">
                    {this.props.state.nameError}
                  </span>  
                    </div>
                  </div>
    
                  
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {i18n.t("SUPPLEMENT_DESCRIPTION")}
                    </label>
                    <div className="col-sm-10">
                      <ReactQuill
                        theme="snow"
                        placeholder={this._.t("SUPPLEMENT_DESCRIPTION_PH")}
                        value={this.props.state.description}
                        onChange={(description) => this.props.handleChangeDescription(description)}
                      />
                      <span className="text-danger">
                    {this.props.state.descriptionError}
                  </span>
                    </div>
                  </div>

                </div>
              </div>
    
              <div className="card">
                <div className="card-body">
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {i18n.t("ITEM_NAME")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        id="nameItem"
                        placeholder={i18n.t("ITEM_NAME_PH")}
                        onChange={this.props.handleChange}
                        value={this.props.state.nameItem}

                      />
                       <span className="text-danger">
                    {this.props.state.nameItemError}
                  </span> 
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="name" className="col-sm-2 col-form-label">
                      {i18n.t("ITEM_PRICE")}
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="number"
                        id="priceItem"
                        placeholder={i18n.t("ITEM_PRICE_PH")}
                        onChange={this.props.handleChange}
                        value={this.props.state.priceItem === 0 || this.props.state.priceItem === null || this.props.state.priceItem === '0' ? '':  this.props.state.priceItem}


                      />
                      
                    </div>
                  </div>
                  <span className="text-danger">
                    {this.props.state.itemsError}
                  </span>  
                  <div className="form-group mb-5 mt-1" style={{ float: "right" }}>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light mr-1"
                        onClick={this.props.addItem}
                      >
                    { this.props.state.editMode ? i18n.t("UPDATE"): i18n.t("ADD") }
                      </button>
                    </div>
                  </div>
                  <ul class="list-group col-lg-12">
                    {this.props.state.items.map((elem, index) => (
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        {elem.name}
                        <span className='ml-auto mr-5'>{elem.price === 0 || elem.price === '' || elem.price === '0' || elem.price === null ? '': elem.price + '$' }</span>
                        <ul class="list-inline m-0">
                          <li class="list-inline-item">
                            <button
                              class="btn btn-success btn-sm rounded-0"
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Edit"
                              onClick={() => this.props.editItem(index)}

                            >
                              <i class="fa fa-edit"></i>
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button
                              class="btn btn-danger btn-sm rounded-0"
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Delete"
                              onClick={() => this.props.deleteItem(index)}

                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default EditForm;