import React, { Component } from 'react'
import { STATIC } from '../../env'

export default class Footer extends Component {
  render() {
    return (
    <footer className="footer">
        © 2020 {STATIC.TITLE} <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by <a href="https://aurax.io/">Aurax STE</a></span>.
    </footer>
    )
  }
}
