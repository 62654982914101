import React, { Component } from 'react'
import TableCategories from '../../components/Categories/TableCategories'
import { getI18n } from 'react-i18next';

class ListCategories extends Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">{getI18n().t('LIST_CATEGORIES')}</h4>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                    <TableCategories />
                </div>

            </div>
        )
    }
}

export default ListCategories;